<div *ngIf="phase" class="phase-progress">
  <p class="fw-bold mb-1">Progress Completed:</p>
  <div class="position-relative">
    <ngb-progressbar
      [value]="formatProgress(phase.progress)"
      [animated]="true"
      [height]="'23px'">
      <span class="fw-bold fs-15 text-start ms-2"
        >{{ formatProgress(phase.progress) }}%</span
      >
    </ngb-progressbar>
    <div class="d-flex mt-1">
      <div *ngIf="phase.progress === 0" class="zero-percent-label">0%</div>
      <div
        *ngFor="let marker of markers"
        class="d-flex justify-content-center position-relative"
        [ngStyle]="{ width: marker.position + '%' }">
        <div class="marker-year">
          {{ marker.year }}
        </div>
        <div class="marker-content">
          <i class="bi bi-caret-up-fill"></i>
        </div>
      </div>
    </div>
  </div>
</div>
